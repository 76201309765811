import React from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Layout from "../../components/layout/layout";
import DevHeading from "../../components/common/developments/devHeading";
import HeadingWithBg from "../../components/common/developments/headingWithBg";
import DevHeadingCentered from "../../components/common/developments/devHeadingCentered";
import DevFeatures from "../../components/common/developments/devFeatures";
import DevFeatures2 from "../../components/common/developments/devFeatures2";
import Newsletter from "../../components/newsletter/newsletter";
import formatTitle from "../../components/common/formatTitle";
import I2vDashboard from "./i2v_dashboard.png";
import Avatar from "../avatar.png";

const useStyles = makeStyles((theme) => ({
  row1: {
    padding: theme.layout.paddingRegular,
  },
  firstRow: {
    padding: theme.layout.paddingRegular,
  },
  lastRow: {
    padding: theme.layout.paddingLastRow,
  },
  bigRow: {
    padding: "10em 15% 10em 15%",
  },
  enLight: {
    color: theme.palette.primary.light,
  },
  regularRow: {
    padding: theme.layout.paddingRegular,
  },
  headingWithBackground: {
    backgroundImage: `url(${I2vDashboard})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 50%",
    // paddingTop: 208,
  },
  centered: {
    textAlign: "center",
  },
  citation: {
    maxWidth: "60%",
    margin: "auto",
    fontSize: "1.2em",
    fontWeight: 1000,
    marginTop: theme.spacing(3),
  },
  citationName: {
    color: theme.palette.primary.light,
    fontSize: "1.3em",
    marginTop: theme.spacing(2),
  },
  citationQualif: {
    marginTop: theme.spacing(2),
  },
}));

const I2V = ({ data, pageContext: { slug, langKey } }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const datasParsed = ["features1", "features2", "punchlines"].reduce(
    (m, k) => ({
      ...m,
      [k]: data.allI2VJson.nodes.find((n) => n[k] !== null)[k][langKey],
    }),
    {}
  );
  return (
    <Layout slug={slug} langKey={langKey} siteMetadata={data.site.siteMetadata}>
      <Grid item container direction="column" alignItems="stretch">
        <HeadingWithBg
          title={datasParsed["punchlines"][0]["title"]}
          content={datasParsed["punchlines"][0]["content"]}
          items={datasParsed["punchlines"][0]["list"].split(",").slice(0, 4)}
          buttonLabel={datasParsed["punchlines"][0]["list"].split(",")[4]}
          className={classes.headingWithBackground}
          src={I2vDashboard}
        />
        <Newsletter langKey={langKey} className={classes.regularRow} />
        <DevFeatures features={datasParsed["features1"]} noIcon />
        <DevHeadingCentered
          title={datasParsed["punchlines"][1]["title"]}
          content={datasParsed["punchlines"][1]["content"]}
          buttonLabel={datasParsed["punchlines"][1]["list"]}
        />
        <DevFeatures2
          features={datasParsed["features2"]}
          imagesDatasFixed={data}
          imagesDatasFixedAccessors={[1, 2, 3, 4, 5, 6].map(
            (idx) => `i2v${idx}`
          )}
        />
        <DevHeading
          title={formatTitle(datasParsed["punchlines"][2]["title"])}
          content={datasParsed["punchlines"][2]["content"]}
          items={datasParsed["punchlines"][2]["list"].split(",").slice(0, 3)}
          buttonLabel={datasParsed["punchlines"][2]["list"].split(",")[3]}
          images={data}
          rowReverse
          imageSizeOnMobile={6}
          templateType={2}
          lastRow
        />
        <Grid item className={clsx(classes.lastRow, classes.centered)}>
          <picture>
            <img src={Avatar} alt="Pierre Delaigue" />
          </picture>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.citation}
          >
            {
              '"Thanks to the algorithm developed by Cyclope.ai, connected and autonomous vehicles will benefit from qualified information on traffic conditions, events and singularities of the motorway network. This data will enable them to anticipate risk situations to provide the user with a safe and continuous autonomous experience."'
            }
          </Typography>
          <p className={classes.citationName}>Pierre Delaigue</p>
          <p className={classes.citationQualif}>
            Director of Autonomous, Connected and Electric Mobility
            <br /> Projects Leonard | VINCI Autoroutes
          </p>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default I2V;

export const query = graphql`
  query I2VQueryFr {
    allI2VJson {
      nodes {
        punchlines {
          fr {
            content
            title
            list
          }
        }
        features2 {
          fr {
            content
            title
          }
        }
        features1 {
          fr {
            content
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        author {
          twitter
        }
        siteTitle
        languages {
          defaultLangKey
          langs
        }
      }
    }
    i2v1: file(relativePath: { eq: "i2v1.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    i2v1_picto: file(relativePath: { eq: "i2v1_picto.png" }) {
      childImageSharp {
        fixed(width: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    i2v2: file(relativePath: { eq: "i2v2.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    i2v2_picto: file(relativePath: { eq: "i2v2_picto.png" }) {
      childImageSharp {
        fixed(width: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    i2v3: file(relativePath: { eq: "i2v3.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    i2v3_picto: file(relativePath: { eq: "i2v3_picto.png" }) {
      childImageSharp {
        fixed(width: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    i2v4: file(relativePath: { eq: "i2v4.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    i2v4_picto: file(relativePath: { eq: "i2v4_picto.png" }) {
      childImageSharp {
        fixed(width: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    i2v5: file(relativePath: { eq: "i2v5.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    i2v5_picto: file(relativePath: { eq: "i2v5_picto.png" }) {
      childImageSharp {
        fixed(width: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    i2v6: file(relativePath: { eq: "i2v6.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    i2v6_picto: file(relativePath: { eq: "i2v6_picto.png" }) {
      childImageSharp {
        fixed(width: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    i2v_acces_universel: file(relativePath: { eq: "i2v_acces_universel.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
